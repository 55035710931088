const CloseIcon = ({ ...props }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.35355 17.3536C7.54882 17.5488 7.8654 17.5488 8.06066 17.3536L12 13.4142L15.9393 17.3536C16.1346 17.5488 16.4512 17.5488 16.6464 17.3536L17.3536 16.6464C17.5488 16.4512 17.5488 16.1346 17.3536 15.9393L13.4142 12L17.3536 8.06066C17.5488 7.8654 17.5488 7.54882 17.3536 7.35355L16.6464 6.64645C16.4512 6.45118 16.1346 6.45118 15.9393 6.64645L12 10.5858L8.06066 6.64645C7.8654 6.45118 7.54882 6.45118 7.35356 6.64645L6.64645 7.35355C6.45119 7.54882 6.45119 7.8654 6.64645 8.06066L10.5858 12L6.64645 15.9393C6.45118 16.1346 6.45118 16.4512 6.64645 16.6464L7.35355 17.3536Z"
			fill="currentColor"
		/>
	</svg>
)

export default CloseIcon
